<template>
  <v-container class="h-screen">
    <v-row class="h-100 align-center">
      <v-col cols="12" sm="8" md="4" offset-sm="2" offset-md="4">
        <NuxtLink :to="localePath('/login')">
          <Logo class="mx-auto mb-8" />
        </NuxtLink>
        <div class="px-8">
          <slot />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>
